import dotenv from "dotenv";
dotenv.config();

export default class ConfigProvider {
  static get CONFIG() {
    return {
      backendHost: "$VUE_APP_API_HOST",
      backendPort: "$VUE_APP_API_PORT",
      backendProtocol: "$VUE_APP_API_PROTOCOL",
      backendBasePath: "$VUE_APP_API_BASE_PATH",
      backendPrefix: "$VUE_APP_API_PREFIX",
      paypalClientId: "$VUE_APP_PAYPAL_CLIENT_ID",
      amchartsLicense: "$VUE_APP_AMCHARTS_LICENSE",
      i18nFallbackLocale: "$VUE_APP_I18N_FALLBACK_LOCALE",
      i18nSupportedLocale: "$VUE_APP_I18N_SUPPORTED_LOCALE",
      i18nLocale: "$VUE_APP_I18N_LOCALE",
      dashboardGoogleAnalyticsId: "$VUE_APP_DASHBOARD_GOOGLE_ANALYTICS_ID"
    };
  }

  static value(name) {
    if (!(name in this.CONFIG)) {
      return;
    }

    const value = this.CONFIG[name];

    if (!value) {
      return;
    }

    if (value.startsWith("$VUE_APP_")) {
      const envName = value.substr(1);
      const envValue = process.env[envName];
      if (envValue) {
        return envValue;
      }
    } else {
      return value;
    }
  }
}
